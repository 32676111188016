import {
  Alert,
  Box,
  Button,
  Group,
  Modal,
  NativeSelect,
  Select,
  Switch,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import {
  corporatesListDataState,
  profileListDataState,
} from "../../recoil_state";
import { patchMethod } from "../../utilities/fetchMethod";
import { DeviceInfo, EditDeviceInfo } from "../../utilities/models";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs, { Dayjs } from "dayjs";
import { DatePicker } from "@mantine/dates";

interface Props {
  deviceInfo: DeviceInfo;
  onClose: () => void;
  onRefesh: () => void;
}
// const findProfileValue = (profileList: any[], deviceInfo: DeviceInfo): string | null => {
//   const profile = profileList.find(x => x.value === deviceInfo.profile.id);
//   return profile?.value ?? null;
// };
export default function EditDeviceModalBox(props: Props) {
  dayjs.extend(customParseFormat);
  const [error, setError] = useState("");
  const profileListData = useRecoilValue(profileListDataState);
  const corporatesListData = useRecoilValue(corporatesListDataState);
  const { state } = useLocation();
  // console.log("props.deviceInfo",props.deviceInfo);

  // Initialize form with parsed dates
  const form = useForm<EditDeviceInfo>({
    initialValues: {
      active:props.deviceInfo.active,
      name: props.deviceInfo.name,
      profile: props.deviceInfo.profile?props.deviceInfo.profile.id.toString():null,
      active_date: props.deviceInfo.active_date
        ? new Date(props.deviceInfo.active_date) 
        : null,
      expired_date: props.deviceInfo.expired_date
        ? new Date(props.deviceInfo.expired_date) 
        : null,
        expired:props.deviceInfo.expired,
        corporates:props.deviceInfo.corporates?props.deviceInfo.corporates.id.toString():null,
        modal:props.deviceInfo.modal,
        follow_primary_profile:props.deviceInfo.follow_primary_profile
    },
    validate: {},
  });

console.log("form",form.values);


  async function editItem(formData: any) {
    setError("");
    console.log("formData", formData);

    let corporate: number | null;
    let profile: number | null;

    if (formData.corporates) {
      corporate =
        parseInt(formData.corporates) === 0
          ? null
          : parseInt(formData.corporates);
    } else {
      corporate = null;
    }

    if (formData.profile) {
      profile =
        parseInt(formData.profile) === 0 ? null : parseInt(formData.profile);
    } else {
      profile = null;
    }

    let newForm: EditDeviceInfo = {
      active: formData.active,
      name: formData.name,
      profile: profile?`${profile}`:null,
      expired_date: formData.expired_date
        ? dayjs(formData.expired_date).format("YYYY-MM-DD HH:mm:ss")
        : null,
      active_date: formData.active_date
        ? dayjs(formData.active_date).format("YYYY-MM-DD HH:mm:ss")
        : null,
        expired:formData.expired,
        corporates: state === "superAdmin"?`${corporate}`:state,
        modal:formData.modal,
        follow_primary_profile:formData.follow_primary_profile
        
    };

  

    console.log("newForm", newForm);

    try {
      const _patchMethod = await patchMethod(newForm);
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/device/${encodeURI(
          String(props.deviceInfo.id)
        )}`,
        _patchMethod
      );
      const result = await res.json();
      if (result.message === "success") {
        props.onRefesh();
        props.onClose();
      } else if (result.error) {
        setError(result.error);
      } else {
        setError("Please try again");
      }
    } catch (error) {
      setError("An error occurred while updating the device");
    }
  }

  return (
    <Modal
      opened={true}
      onClose={props.onClose}
      overlayColor={"grey"}
      overlayOpacity={0.55}
      overlayBlur={3}
      overflow="inside"
      title={`Edit Device : ${props.deviceInfo.device_id}`}
      key={`${props.deviceInfo.id}`}
    >
      <Box sx={{ maxWidth: 300 }} mx="auto">
        <Alert>{`This device is currently linked to ${
          props.deviceInfo.profile
            ? props.deviceInfo.profile.username
            : "Nobody"
        }`}</Alert>

        <form onSubmit={form.onSubmit((formData) => editItem(formData))}>
          {Object.entries(props.deviceInfo).map(([key, info]) => (
            <div key={key}>
              {key === "name" && (
                <TextInput
                  withAsterisk
                  label={"Name"}
                  key={`${props.deviceInfo.id}--${key}`}
                  {...form.getInputProps(`${key}`)}
                />
              )}

              {key === "profile" && (
                <Select
                  data={profileListData}
                  label="Select Profile"
                  placeholder="profile username"
                  radius="xs"
                  searchable
                  
                  // min={0}
                  {...form.getInputProps(`${key}`)}
                />
              )}

              {key === "active" && (
                <Switch
                  key={`${props.deviceInfo.id}--${key}`}
                  style={{ margin: 15 }}
                  defaultChecked={info}
                  labelPosition="left"
                  onLabel="Yes"
                  offLabel="No"
                  label={"Active"}
                  {...form.getInputProps(`${key}`, { type: "checkbox" })}
                />
              )}

           

              {key === "active_date" && (
                <div style={{ marginTop: "10px" }}>
                  <DatePicker
                    style={{ marginRight: 10 }}
                    placeholder="Pick date"
                    label="Active Date"
                    
                    {...form.getInputProps(`${key}`, { type: "input" })}
                  />
                </div>
              )}

              {key === "expired_date" && (
                <div style={{ marginTop: "10px" }}>
                  <DatePicker
                    style={{ marginRight: 10 }}
                    placeholder="Pick date"
                    label="Expired Date"
                    {...form.getInputProps(`${key}`, { type: "input" })}
                  />
                </div>
              )}

              {state === "superAdmin" && (
                <>
                  {key === "corporates" && (
                    <NativeSelect
                      data={corporatesListData}
                      placeholder={"select corporates"}
                      label={"Corporate Name"}
                      
                      {...form.getInputProps(`${key}`)}
                    />
                  )}
                  {key === "modal" && (
                    <TextInput
                      placeholder={"Loop card"}
                      label={"Modal Type"}
                      withAsterisk
                      {...form.getInputProps(`${key}`)}
                    />
                  )}
                </>
              )}
               {key === "follow_primary_profile" && (
                <Switch
                  key={`${props.deviceInfo.id}--${key}`}
                  style={{ margin: 15 }}
                  defaultChecked={info}
                  onLabel="Yes"
                  offLabel="No"
                   labelPosition="left"
                  label={"Follow Primary Profile"}
                  {...form.getInputProps(`${key}`, { type: "checkbox" })}
                />
              )}
            </div>
          ))}
          <div style={{ color: "red", fontSize: 15 }}>{error}</div>
          <Group position="right" mt="md">
            <Button type="submit">Submit</Button>
          </Group>
        </form>
      </Box>
    </Modal>
  );
}
